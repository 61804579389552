<template>
  <div class="polocy">
    <titleNav title="更多文章" :left-arrow="true" left-text="" />
    <div
      class="lists"
      v-for="(item, index) in datas"
      :key="index"
      @click="noticedetail(item)"
    >
      <div class="images">
        <img :src="item.cover" alt="" />
      </div>
      <div class="sizes">
        <div class="tit_p">{{ item.title }}</div>
        <div class="com_p">{{ item.time }} e证e卡</div>
      </div>
    </div>
    <div v-if="tost!=10" class="znawu">暂无更多</div>
    <div v-else class="znawu">加载中...</div>
  </div>
</template>

<script>
import {
  Button,
  Dialog,
  Icon,
  Toast,
  Field,
  Tabbar,
  TabbarItem,
  NoticeBar,
} from "vant";
import { notice } from "@/network/index.js";
import titleNav from "@/components/conetnts/titleNav.vue";
export default {
  components: {
    titleNav,
    [Button.name]: Button,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Field.name]: Field,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      datas: [],
      page: 1,
      tost:0
    };
  },

  created() {
    window.addEventListener("scroll", this.Scrollbottom); //页面加载时监听滚动事件
  },

  destroyed() {
    window.removeEventListener("scroll", this.Scrollbottom); //页面离开后销毁监听事件
  },
  mounted() {
    this.getlist();
  },

  methods: {
    Scrollbottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight > scrollHeight-1) {
        this.page=this.page+1
        // if(this.tost==10){
          this.getlist()
        // }
        
      }
    },
    // 文章列表
    getlist() {
      let moduleid = 13;
      if (this.$route.query.id == 1) {
        moduleid = 13;
      } else {
        moduleid = 12;
      }
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
        moduleid,
        page: this.page,
      };
      notice(data).then((res) => {
        for(let i=0;i<res.data.data.length;i++){
          this.datas.push(res.data.data[i])
        }
        this.tost=res.data.data.length
      });
    },
    // 跳转到文章详情
    noticedetail(e) {
      console.log(e);
      if (e.url == null || e.url == "") {
        this.$router.push("/noticedetail?id=" + e.id);
      } else {
        window.location.href = e.url;
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.znawu{
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  color: rgb(155, 155, 155);
}
.polocy {
  width: 100%;
  .lists {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 10px;
    .images {
      width: 35%;
      height: 80px;
      border-radius: 4px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .sizes {
      width: 63%;
      height: 80px;
      position: relative;
      .tit_p {
        width: 100%;
        font-size: 18px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .com_p {
        position: absolute;
        bottom: 0;
        font-size: 12px;
        color: rgb(158, 158, 158);
      }
    }
  }
  .zwgd {
    text-align: center;
    font-size: 14px;
    color: rgb(83, 83, 83);
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
</style>